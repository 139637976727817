#servicesMain {
    padding-block: 0;
}

#servicesMain > article {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    padding-inline: 4vw;
}

#servicesMain > article > section {
    margin-block: 4vh;
    padding-inline: 4vw;
}

#servicesMain > article > section:first-of-type > p:first-of-type {
    font-style: italic;
}

#servicesMain > article > section:nth-of-type(2) > p:first-of-type {
    font-style: italic;
}

#servicesMain > article > section:nth-of-type(3) > p:first-of-type {
    font-style: italic;
}

#servicesMain > * h1 {
    margin-top: 1vh;
    text-align: center;
}

#servicesMain > * h2 {
    margin-top: 1vh;
    text-align: center;
}

@media (min-width: 768px) {
    #servicesMain > article {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    #servicesMain > article > section {
        max-width: 45%;
        margin-inline: 2vw;
        padding-inline: 2vw;
        padding-block: 2vh;
    }
}