#footer {
    display: flex;
    flex-direction: row;
    min-height: 10vh;
    height: 10vh;
    max-height: 10vh;
}

#footer > div:first-of-type {
    width: 100%;
}

#footer > div:first-of-type > p {
    font-size: 1em;
}

#footer > div:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    line-height: 0.8em;
    color: white;
}