#landingMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-inline: 5vw;
}

#landingMain > article {
    min-width: 90%;
}

#landingMain > article > h2 {
    margin-top: 0;
}

@media (min-width: 768px) {
    #landingMain {
        padding-inline: 0;
    }
}