#privacyPolicyMain > article {
    text-align: center;
    margin-block: 5vh;
    padding-inline: 2vw;
}

@media (min-width: 768px) {
    #privacyPolicyMain {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}