#aboutMain > section {
    padding-inline: 5vw;
}

#aboutMain > section > article > h4 {
    text-align: right;
}

@media (min-width: 768px) {
    #aboutMain > section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #aboutMain > section > article {
        width: 100%;
    }
}