* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, li {
    color: white;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

p, li {
    font-size: 1.1em;
}

li {
    font-weight: 600;
}

body {
    padding: 0;
    margin: 0;
}

header {
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
}

main {
    min-height: 84vh;
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
    padding-block: 3vh;
}

footer {
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
}

@media (min-width: 768px) {
    p {
        font-size: 1.4em;
    }

    main {
        padding-block: 4vh;
    }
}