#pricingMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

#pricingMainTitle > article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#pricingMainTitle > article > h2, h3 {
    text-align: center;
    margin-top: 0;
}

#pricingMainContent {
    display: flex;
    flex-direction: row;
}

#pricingMainContent > article {
    display: flex;
    flex-direction: row;
    margin-block-end: 2vh;
    width: 100vw;
    padding-inline: 5vw;
}

#pricingMainContent > article > section:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}

#pricingMainContent > article > section:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
}

#pricingMainContentSecondary {
    display: flex;
    flex-direction: column;
    margin-block-end: 2vh;
}

#pricingMainContentSecondary > article > section:first-of-type {
    padding-inline: 5vw;
}

#pricingMainContentSecondary > article > section:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    padding-inline: 5vw;
    margin-top: -7.5vh;
}

#pricingMainContentSecondary > article > section:nth-of-type(2) > section:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}

#pricingMainContentSecondary > article > section:nth-of-type(2) > section:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
}

#pricingMainBottom {
    padding-inline: 5vw;
}

#pricingMainBottom > article > h2 {
    text-align: center;
    margin-top: 0;
}

@media (min-width: 768px) {
    #pricingMainContent {
        max-width: 100%;
    }

    #pricingMainContent > article {
        max-width: 100%;
        padding-inline: 25vw;
    }

    #pricingMainContentSecondary > article > section:first-of-type {
        padding-inline: 0;
        text-align: center;
    }

    #pricingMainContentSecondary > article > section:last-of-type {
        padding-inline: 0;
    }

    #pricingMainBottom > article > p {
        padding-inline: 0;
        text-align: center;
    }

    #pricingMainBottom > article > h2 {
        margin-block-start: 5vh;
        margin-block-end: 3vh;
    }
}